import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import UserDataForm from "../../global-elements/userDataForm";
import ColorCardChoiceForm from "../../global-elements/colorCardChoiceForm";
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import marketing from "../../../../services/marketing";
import "../../../../assets/terms/dm-terms.css";
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import { useEffect } from "react";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import PixelsService from '../../../../services/pixels';
import ConfirmSmsConsent from "../../global-elements/confirmsSmsConsent";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import SubmitApplicationService from "../../../../services/submitApplication";
import Econsent from '../../global-elements/eConsent';
import FeaturesService from "../../../../services/features";

function Terms() {
  const useRecaptcha = useStoreState((state) => state.siteSettingsModel.useRecaptcha);
  const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const setGrandBanckDeclined = useStoreActions(
    (actions) => actions.siteSettingsModel.setGrandBanckDeclined
  );
  
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions(
    (actions) => actions.applicantModel.setApplicant
  );
  const text = useStoreState((state) => state.dmText.terms);
  const setApplicationResult = useStoreActions(
    (actions) => actions.applicationResultModel.setApplicationResult
  );
  const brandSettings = useStoreState(
    (state) => state.siteSettingsModel.brandSettings
  );
  const alResult = useStoreState(state => state.alResult);
  const setAlResult = useStoreActions(actions => actions.setAlResult);
  const Flow = useStoreState(state => state.siteSettingsModel.mainSettings.Flow);
  const WaterfallEvents = { "DM" : 10, "PREQUAL" : 6, "CFC" : 18, "STANDALONE" : 24, "CS" : 29};
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const setInvalidState = useStoreActions((actions) => actions.setInvalidState);
  const history = useHistory();
  const wildcard = new Wildcard();
  const validatorService = new ValidatorService();
  const methods = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const utms = new marketing();
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const closeError = () => setErrorShow(false);
  const pendingInfo =  ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const termsPixels = useStoreState(state => state.termsPixels);
  const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
  const PixelService = new PixelsService();
  const submitApplicationService = SubmitApplicationService();
  const features = new FeaturesService();
  const setDeclineOffers = useStoreActions(actions => actions.siteSettingsModel.setDeclineOffers);

  useEffect(() => {
    if (!termsPixels) {
      PixelService.pageView("/Terms").event("google","DM","Terms");
      
      setTermsPixel(true)
    }
    getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  const submit = async (data) => {
    setLoading(true)
    //PATCH remeber to remove this
    if(data.State === 'WA'){
      setInvalidState(true); 
      setApplicationResult({result : "INVALIDSTATE"}) 
      return;
    }
    ///////////////////////////////////
        if (!validatorService.preValidations(data, methods, info)) {
            let result = await validatorService.addressValidate(info, methods, setInvalidState);
            if(result === "INVALIDSTATE") { 
                setInvalidState(true); 
                setApplicationResult({result : "INVALIDSTATE"}) 
                return;
            }
            if(result !== false){
                await submitApplication();
            }
        }
    setLoading(false)
  }

  async function submitApplication() {
    const response = await submitApplicationService.submitApplication(info);

    if (response) {
      if (response.IsGrandbank === true) {
        setGrandBanckDeclined(true);
      }

      if (response.result != null) {
        //Marketing tracking
        utms.updateSessionReport( "appResult"  , response.result.toUpperCase())
        utms.updateSessionReport( "applicantId", response.ref_number || "");
        
        //Decline Offers for BMG box
        setDeclineOffers(response.DeclineOffers || null);

        setApplicationResult(response);
        setInfo({ index: "step", value: 3 });
        resultStep(true);
        window.scrollTo(0, 0);

        if(response.result.toUpperCase() === "PENDFEE" && Setting.brandSettings.ProductName.toLowerCase() === 'fit'){
          if(alResult === false){
            PixelService.pageView("/approved").approvedCalls(response.ref_number)
           
            utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, response.ref_number, Setting.productSettings.ProductId, "Flow");
            
            setAlResult(true)
          }
          const url = "https://" + ((process.env.REACT_APP_CFC_APPLICANT_BUILDENV !== "PRODUCTION") ? "test" : "") +"offer.your"+brandSettings.ProductName.toLowerCase()+"card.com/payment/?RefNo="+response.ref_number
          window.location.replace(url); 
          return;
        }
        
        if (response.result.toUpperCase() === "APPROVED") {
         
          history.push("/approved" + window.location.search);
          return;
        }

        if(features.isFeatureEnabled('FlinksActive')  && response.result.toUpperCase() === 'PENDUSER') {
          history.push("/link-account"+window.location.search); return;
        }

        if (pendingInfo.includes(response.result.toUpperCase())) {
          history.push("/pendinfo" + window.location.search);
          return;
        }
        history.push("/declined" + window.location.search);
        return;
      }
    } else {
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
      setLoading(false);
    }
  }

  return (
    <>
      <InvalidStateModal />
      <div
        className="row"
        id="header-text-container"
        style={{ backgroundColor: Setting.brandSettings.Primary }}
      >
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <ErrorModal
          size="sm"
          show={errorShow}
          message={errorMessage}
          closeError={closeError}
        />
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(submit)}
          >
            <div className="dm-form">
              <UserDataForm />
            </div>
            {Setting.productSettings.ProductId === 1 || Setting.productSettings.ProductId === 10 ? (
              <div className="row">
                <ColorCardChoiceForm />
              </div>
            ) : null}

            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">E-Sign Disclosure</h1>
              </div>
              <Esign />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Pricing and Terms</h1>
              </div>
              <div className="col-12 ">
                <div className="terms-wrapper">
                  <TC data={{ productCode: info.ProductCode }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Credit Protection</h1>
              </div>
              <CreditProtection />
            </div>
            
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Acknowledgements</h1>
              </div>
              <Econsent />
              <Acknowledgements data={{ productCode: info.ProductCode }} />
            </div>
            {Setting.smsConcentModuleDM === true && (
                <div className="row mt-4 pr-0 pl-3">
                    <div className="col-12">
                        <ConfirmSmsConsent></ConfirmSmsConsent>
                    </div>
                </div>
            )}
            <div className="row mt-3">
                <div className="captcha-container">
                    {useRecaptcha && Setting.productSettings.CaptchaKey !== null ? (
                        <div className="form-group displayFlexCenter">
                        <Recaptcha
                            setRecaptchaDisabled={setRecaptchaDisabled}
                            ProductName={brandSettings.ProductName}
                        />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className="row mt-1">
              <div className="col-12 text-center">
                <button
                onClick={() => {utms.trackEvent(11, "DM", "", Setting.productSettings.ProductId, "Flow");PixelService.event("google", "DM", "Accept Offer")}}
                  disabled={isLoading || ((useRecaptcha && Setting.productSettings.CaptchaKey !== null) &&recaptchaDisabled)}
                  id="dm-submit-app"
                  style={{
                    backgroundColor: Setting.brandSettings.Tertiary,
                    width: "17%",
                    color: "#fff",
                  }}
                  className="btn text-small mt-3 rounded-pill"
                  type="submit"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
            
      
    </>
  );
}

export default Terms;
