import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import UserDataForm from "../../global-elements/userDataForm";
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import "../../../../assets/terms/dm-terms.css";
import marketing from "../../../../services/marketing";
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import Recaptcha from "../../../body/forms/components/recaptcha/Recaptcha";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import PixelsService from "../../../../services/pixels";
import AudioEyeSupport from "../../../../services/audioEyeSupport";
import ConfirmSmsConsent from "../../global-elements/confirmsSmsConsent";
import SubmitApplicationService from "../../../../services/submitApplication";
import Econsent from '../../global-elements/eConsent';
import AdobeScript from "../../../../services/adobeScript";

function Apply() {
  const ada = new AudioEyeSupport();
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const setGrandBanckDeclined = useStoreActions((actions) => actions.siteSettingsModel.setGrandBanckDeclined);
  const info = useStoreState((state) => state.applicantModel.applicant);
  const alResult = useStoreState((state) => state.alResult);
  const setAlResult = useStoreActions((actions) => actions.setAlResult);
  const Flow = useStoreState((state) => state.siteSettingsModel.mainSettings.Flow);
  const setInfo = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const setApplicationResult = useStoreActions((actions) => actions.applicationResultModel.setApplicationResult);
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const history = useHistory();
  const getRoutingSettings = useStoreActions((state) => state.routingSettingsModel.getRoutingSettings);
  const [errorShow, setErrorShow] = useState(false);
  const closeError = () => setErrorShow(false);
  const InvalidState = useStoreState((state) => state.invalidState);
  const setInvalidState = useStoreActions((actions) => actions.setInvalidState);
  const [errorMessage, setErrorMessage] = useState(false);
  const validatorService = new ValidatorService();
  const methods = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const adobeAds = new AdobeScript()
  const useRecaptcha = useStoreState((state) => state.siteSettingsModel.useRecaptcha);
  const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
  const [recaptchaDisabled, setRecaptchaDisabled] = useState(true);
  const utms = new marketing();
  const pendingInfo = [
    "FROZEN",
    "FROZENPREQUAL",
    "PENDFEE",
    "PENDFUNDING",
    "PENDINFO",
    "PENDINTERNAL",
  ];
  const setInitPixels = useStoreActions((actions) => actions.setInitPixels);
  const initPixels = useStoreState((state) => state.initPixels);
  const PixelService = new PixelsService();
  const Submit = SubmitApplicationService();
  const WaterfallEvents = { "ACTIVATION_PAYMENT": 36 };
  const setDeclineOffers = useStoreActions(actions => actions.siteSettingsModel.setDeclineOffers);
  const [isHalfScrolled, setIsHalfScrolled] = useState(false);

  useEffect(() => {
    if (!initPixels) {
      utms.mapper();
      ada.setup().setDYHeaders(process.env.REACT_APP_PAYMENTFLOW).setDYContext({ type: "HOMEPAGE" }).DYLandingEvent();
      adobeAds.setup()
      setTimeout(() =>  adobeAds.startEvent('page_view'), 5000);
      utms.trackEvent(WaterfallEvents[Flow] || 0, Flow, "", Setting.productSettings.ProductId, "Flow");
      PixelService.initialize().pageView("/landing").event("google", "tracking", "Landing Page");
      getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname });
      setInfo({ index : 'ProductCode', value : Setting.mainSettings.ProductCode })
      setInitPixels(true)
      setAlResult(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSubmition = async (data) => {
        
    setLoading(true)
        //PATCH remeber to remove this
        if(data.State === 'WA'){
          setInvalidState(true); 
          setApplicationResult({result : "INVALIDSTATE"}) 
          return;
        }
        ///////////////////////////////////
        if (!validatorService.preValidations(data, methods, info)) {
                let result = await validatorService.addressValidate(info, methods, setInvalidState);
                if(result === "INVALIDSTATE") { 
                    setInvalidState(true); 
                    setApplicationResult({result : "INVALIDSTATE"}) 
                    return;
                }
                if(result !== false){
                    await submit();
                }
            
        }
    setLoading(false)
  };

  useEffect(() => {
    const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.scrollHeight;
    const scrolledToBottom = scrollPosition + windowHeight;

    if (scrolledToBottom >= fullHeight / 2) {
      console.log()
      if(isHalfScrolled === false){
          
          setIsHalfScrolled(true)
      }
      
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHalfScrolled]);

  useEffect(() => {
  if (isHalfScrolled) {
      scrollEvent();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHalfScrolled]);

  const scrollEvent = () => {
  adobeAds.event('scroll')
  };

  async function submit() {

    let response = await Submit.submitApplication(info, true);
    let payload = response.Payload;

    if (response.Success) {
      if (payload) {
        if (payload.IsGrandbank === true) { setGrandBanckDeclined(true) }

        if (payload.result != null) {
          utms.updateAppId(payload.ref_number || "");
          if (payload.result) {
            utms.updateSessionReport("appResult", payload.result.toUpperCase());
          }
          if (payload.ref_number) {
            utms.updateSessionReport("applicantId", payload.ref_number);
          }

           //Decline Offers for BMG box
          setDeclineOffers(payload.DeclineOffers || null);

          setInfo({ index: "step", value: 3 });
          setApplicationResult(payload);
          resultStep(true);
          window.scrollTo(0, 0);

          if (payload.result.toUpperCase() === "PENDFEE" && Setting.brandSettings.ProductName.toLowerCase() === "fit") {
            if (alResult === false) {
              PixelService.pageView("/approved").approvedCalls(
                payload.ref_number
              );

              utms.trackEvent(
                WaterfallEvents[Flow] || 0,
                Flow,
                payload.ref_number,
                Setting.productSettings.ProductId,
                "Flow"
              );

              setAlResult(true);
            }
            history.push("/pendfee");
            return;
          }
          if (payload.result.toUpperCase() === "APPROVED") {
            history.push("/approved" + window.location.search);
            return;
          }
          if (pendingInfo.includes(payload.result.toUpperCase())) {
            history.push("/pendinfo" + window.location.search);
            return;
          }
          history.push("/declined" + window.location.search);
          return;
        }
      } else {
        setErrorMessage("An unknown error has occured. Please try again.");
        setErrorShow(true);
        setLoading(false);
      }
    }
    else {
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
      setLoading(false);
    }
  }

  return (
    <>
      <ErrorModal
        size="sm"
        show={errorShow}
        message={errorMessage}
        closeError={closeError}
      />
      <InvalidStateModal visible={InvalidState} />
      <div className="container mt-5 mb-5">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(validateSubmition)}>
            <div className="dm-form">
              <UserDataForm />
            </div>

            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">E-Sign Disclosure</h1>
              </div>
              <Esign />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Pricing and Terms</h1>
              </div>
              <div className="col-12 ">
                {/* TODO: UNCOMMENT THIS WHEN WE HAVE SAMPLES!!!
                                <TC data={{productCode: info.ProductCode}} /> */}
                <div className="terms-wrapper">
                  <TC
                    data={{ productCode: Setting.mainSettings.ProductCode }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Credit Protection</h1>
              </div>
              <CreditProtection />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Acknowledgements</h1>
              </div>
              <Econsent />
              <Acknowledgements data={{ productCode: Setting.mainSettings.ProductCode }} />
            </div>
            {Setting.smsConcentModuleCFC === true &&
                <div className="row mt-4 pr-0 pl-3">
                  <div className="col-12">
                    <ConfirmSmsConsent></ConfirmSmsConsent>
                  </div>
                </div>
            }
            <div className="row ">
              <div className="captcha-container">
                {useRecaptcha && Setting.productSettings.CaptchaKey !== null ? (
                  <div className="form-group displayFlexCenter">
                    <Recaptcha
                      setRecaptchaDisabled={setRecaptchaDisabled}
                      ProductName={brandSettings.ProductName}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 text-center">
                <button
                  disabled={
                    isLoading ||
                    (useRecaptcha &&
                      Setting.productSettings.CaptchaKey !== null &&
                      recaptchaDisabled)
                  }
                  onClick={() => {
                    adobeAds.event('apply_now_click')
                  }}
                  id="payment-submit-app"
                  style={{ 
                    backgroundColor: Setting.brandSettings.BannerBgColor, 
                    width: "auto",
                    opacity: isLoading ? 0.5 : 1, 
                    marginTop: "1.5em",
                    border: "1px solid transparent",
                    color: "white"
                  }}
                  className="pay-processing-button"
                  type="submit"
                >
                  {isLoading ? "Submitting..." : "Submit Application"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default Apply;
